@import "../../styles/Colors.scss";

.dialog {
    background-color: transparent !important;
}

.form {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 2em;
    border-radius: 0.3em;
}

.descriptionText {
    margin: 1.5em 0;
}

.rowDiv {
    display: flex;
    justify-content: space-between;
}

.twoFACodeClass {
    flex: 3;
}

.twoFACodeInput {
    direction: ltr;
    text-align: center;
}

.timerDiv {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.timerSpan {
    margin-top: 0.2em;
}

#timerIcon {
    width: 0.8em;
    height: auto;
}

.sendCodeButton {
    flex: 1;
    height: 2.8em;
    margin-right: 1em;
}

.errorMessage {
    margin-top: 0.5em;
}

.buttonsDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
}

.confirmButton {
    flex: 2;
    margin-right: 1em;
}

.logoutButton {
    flex: 1;
    color: $primary;
    background-color: transparent;
    border: 1px solid $primary;
    margin-left: 1em;
}

@media screen and (max-width: 600px) {
    .form {
        padding: 1em;
    }
}
