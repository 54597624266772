@import "../../styles/Colors.scss";

.appBar {
    background-color: transparent !important;
    border-bottom: 1px solid $dividerSepratorColor !important;
    box-shadow: none !important;
}

.toolbarRoot {
    height: 64px;
    display: flex;
    background-color: $menuBackground;
}

.mainDiv {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;
}

.logoDiv {
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    font-weight: 500;
}

.logo {
    width: 2.2em;
    height: 2.2em;
    margin-left: 0.4em;
}

#menuIcon {
    width: 1.4em;
    height: auto;
}

.blogButton {
    cursor: pointer;
    margin-right: 1.5em;
}

@media screen and (min-width: 600px) {
    .toolbarRoot {
        padding-right: 0 !important;
    }

    .logoDiv {
        width: 72px;
    }

    .logo {
        width: 2.5em;
        height: 2.5em;
        margin-left: 0;
    }
}
