@import "../../../styles/Colors.scss";

$paddingHorizontal: 1em;

.main {
    display: flex;
    margin-left: 1em;
}

.optionsMenuDiv {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $menuLanding;
}

.optionsMenuDiv:hover {
    color: $primary;
}

#menuIcon {
    width: 1.4em;
    height: auto;
}

.swipDrawer {
    min-width: 55vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: $menuLanding;
    background-color: $menuBackground;
    padding: $paddingHorizontal;
}

.logoDiv {
    display: flex;
    align-items: center;
}

.logoIcon {
    width: 2em;
    height: auto;
    margin-top: 0.1em;
}

.logoTextsDiv {
    display: flex;
    flex-direction: column;
    color: $primary;
    margin-right: 0.6em;
}

.logoTitleText {
    font-weight: 500;
}

.levelupButton {
    padding: 0.5em;
    margin: 1em 0 0.5em;
}

.referralButton {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
    padding: 0.5em;
    margin: 0.5em 0;
}

.referralIcon {
    width: 1.4em;
    height: auto;
    margin-left: 0.5em;
}

.separator {
    height: 1px;
    background-color: $dividerSepratorColor;
    margin: 0.5em (-1 * $paddingHorizontal);
}

.menuOptionsItemIconDiv {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.3em 0;
    margin-top: 0.2em;
}

.menuOptionsItemIconDiv:first-child {
    margin-top: 0;
}

.menuOptionsItemDiv {
    cursor: pointer;
    display: flex;
    padding: 0.3em 0;
    margin-top: 0.2em;
    margin-right: 1.7em;
}

.menuOptionsItemDiv:first-child {
    margin-top: 0;
}

.menuOptionIcons {
    width: 1.2em;
    height: 1.2em;
    margin-left: 0.5em;
}

.registerButton {
    width: 100%;
    margin-top: 0.5em;
}

.loginButton {
    width: 100%;
    color: $menuLanding;
    background-color: transparent;
    margin: 1.5em 0 1em;
}

@media screen and (min-width: 600px) and (max-width: 960px) {
    .swipDrawer {
        min-width: 40vw;
    }
}
