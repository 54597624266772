@import "../../styles/Colors.scss";

.main {
    display: flex;
    flex-direction: column;
    background-color: $menuBackground;
    padding: 0.5em 1.5em 0;
}

.titleSpan {
    color: $menuTitle;
    margin-bottom: 0.5em;
}

.tabsDiv {
    display: flex;
}

.hidden {
    display: none;
}
