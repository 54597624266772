@import "../../styles/Colors.scss";

.toolbarRoot {
    height: 64px;
    display: flex;
    align-items: center;
    background-color: $navbarBackground;
}

.logoDiv {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $primary;
    font-weight: 500;
}

.logo {
    width: 2.5em;
    height: 2.5em;
    margin-left: 0.4em;
}
