@import "../../styles/Colors.scss";

.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    color: $inputLabel;
    padding: 0 0.2em 0.2em;
}

.labelText {
    color: $inputLabel;
}

.hintText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: $secondaryText;
    background-color: $inputBackground;
    padding: 0.1em 0.6em 0;
    border-radius: 0.3em;
}

.textareaInput,
.iconInputDiv {
    position: relative;
    display: flex;
    width: calc(100% - 1.6em);
    padding: 0.5em 0.7em;
    box-shadow: none;
    color: $darkModeText;
    background-color: $inputBackground;
    border: 1px solid $inputBorderDefault;
    border-radius: 0.2em;
}

.input {
    min-height: 1.2em;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
    border: none;
    color: $inputText;
    padding: 0;
}

.textareaInput:hover:enabled,
.iconInputDiv:hover:enabled,
.iconInputDiv:focus-within {
    border-color: $primary;
}

.textareaInput:focus {
    outline: none;
    border-color: $primary;
    color: inherit;
}

.input:focus {
    outline: none;
}

.input:disabled {
    color: $disabledText;
}

.inputIconLeft {
    position: absolute;
    left: 0.4em;
    top: 50%;
    transform: translateY(-50%);
}

.inputIconRight {
    position: absolute;
    right: 0.4em;
    top: 50%;
    transform: translateY(-50%);
}

.errorClass {
    border-color: $errorText;
}

.errorText {
    width: 100%;
    text-align: right;
    color: $errorText;
    margin: 0.5em 0.3em 0 0;
}

.codeInput {
    direction: ltr;
    text-align: center;
}

.rightAdornmentInput {
    padding-right: 1.5em;
}

.leftAdornmentInput {
    padding-left: 1.5em;
}
