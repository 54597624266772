.main {
    display: flex;
    width: 100%;
    margin-top: 64px;
    padding-bottom: 64px;
}

.dataDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 0;
}

@media screen and (min-width: 600px) {
    .main {
        height: calc(100% - 64px);
        padding-bottom: 0;
    }

    .dataDiv {
        margin-right: 72px;
    }
}
