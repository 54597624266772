@import "../../styles/Colors.scss";

.main {
    display: flex;
    align-items: flex-start;
    color: $errorMessage;
    background-color: $errorMessageBackground;
    text-align: justify;
    padding: 0.5em;
    border-radius: 0.2em;
}

#errorIcon {
    width: 0.9em;
    height: auto;
    margin: 0.1em 0 0 0.2em;
}

.hiddenMessage {
    display: none;
}
