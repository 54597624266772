/**
*
*	Name:			IRANSansX Fonts
*	Version:		1.0
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		Apr 22, 2021
*	Updated on:		Apr 22, 2021
*	Website:		http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونتایران سنس Xا یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
IRANSansX fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
------------------------------------------------------------------------------------- fonts/-
*	
**/

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: 100;
	src: url("../../assets/fonts/woff/IRANSansX-Thin.woff") format("woff"),
		url("../../assets/fonts/woff2/IRANSansX-Thin.woff2") format("woff2");
}

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: 200;
	src: url("../../assets/fonts/woff/IRANSansX-UltraLight.woff") format("woff"),
		url("../../assets/fonts/woff2/IRANSansX-UltraLight.woff2") format("woff2");
}

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: 300;
	src: url("../../assets/fonts/woff/IRANSansX-Light.woff") format("woff"),
		url("../../assets/fonts/woff2/IRANSansX-Light.woff2") format("woff2");
}

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: 500;
	src: url("../../assets/fonts/woff/IRANSansX-Medium.woff") format("woff"),
		url("../../assets/fonts/woff2/IRANSansX-Medium.woff2") format("woff2");
}

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: 600;
	src: url("../../assets/fonts/woff/IRANSansX-DemiBold.woff") format("woff"),
		url("../../assets/fonts/woff2/IRANSansX-DemiBold.woff2") format("woff2");
}

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: 800;
	src: url("../../assets/fonts/woff/IRANSansX-ExtraBold.woff") format("woff"),
		url("../../assets/fonts/woff2/IRANSansX-ExtraBold.woff2") format("woff2");
}

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: 900;
	src: url("../../assets/fonts/woff/IRANSansX-Black.woff") format("woff"),
		url("../../assets/fonts/woff2/IRANSansX-Black.woff2") format("woff2");
}

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: bold;
	src: url("../../assets/fonts/woff/IRANSansX-Bold.woff") format("woff"),
		url("../../assets/fonts/woff2/IRANSansX-Bold.woff2") format("woff2");
}

@font-face {
	font-family: IRANSansX;
	font-style: normal;
	font-weight: normal;
	src: url("../../assets/fonts/woff/IRANSansX-Regular.woff") format("woff"),
		url("../../assets/fonts/woff2/IRANSansX-Regular.woff2") format("woff2");
}

@font-face {
	font-family: Bahnschrift;
	font-style: normal;
	font-weight: normal;
	src: url("../../assets/fonts/Bahnschrift.ttf") format("ttf");
}
