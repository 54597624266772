$primary: #ff8f20;
$backgroundButton: #53461d;
$primaryBackground: #403b2c;
$homeBackground: #eee;
$errorText: #ff3f3f;
$inputLabel: #e4e6eb;
$inputText: #e4e6eb;
$darkModeIcon: #e4e6eb;
$inputBackground: #2f3133;
$inputBorderDefault: #2f3133;
$enabledButton: #121519;
$enabledButtonBackground: $primary;
$disabledButton: #242526;
$disabledButtonBackground: #939393;
$navbarBackground: #121519;
$menuBackground: #242526;
$menuTitle: #b4b3b3;
$containerBackground: #242526;
$success: #1ade7c;
$loadingBackground: #2f3133;
$captchaBackground: #121519;
$disableIcon: #494a4b;
$descriptionBackground: #494a4b;
$dividerSepratorColor: #494a4b;
$secondaryTextColor: #bbb;
$error: #ef6363;
$secondaryButtonBackground: #2f3133;
$secondaryButtonColor: #e5e5e5;
$disabledStepBackground: #606060;
$itemBackground: #2f3133;
$secondaryItemBackground: #343638;
$qrCodeBackground: #fafafa;
$qrCodeEmptyBackground: #3b3e41;
$itemColor: #939393;
$borderColor: #707070;
$imageBorderColor: #e4e6eb;
$borderseprator: #4d4d4d;
$hoverTableRowBackground: #505050;
$hoverTableHeaderBackground: #2b2e31;
$tableBorder: #2f3133;
$dropDownMenu: #e5e5e5;
$dropDownMenuBackground: #2f3133;
$menuHoverBackground: #383a3c;
$errorMessage: #f84960;
$errorMessageBackground: #3f2629;
$secondaryText: #d4d4d4;
$darkModeText: #fafafa;
$disabledText: #929aa6;
$titleText: #9e9e9e;
$descriptionText: #ddd;
$pText: #505050;
$lightModeText: #505050;
$lightModeHeaderText: #121212;
$landingGridBackground: #323230;
$landingGridText: #eee;
$lightModeBackground: #fcfcfc;
$footerBackground: #242526;
$footerCopyrightBackground: #4a4949;
$footerCopyrightText: #e8e8e8;
$footerItemsTitle: #eee;
$footerItems: #9e9e9e;
$footerIcons: #312e2e;
$convertCrypto-light: #c7c7c7;
$convertCrypto: #3c3d3e;
$convertCryptoText-light: #121212;
$convertCryptoText: #d4d4d4;
$convertAmount-light: #eee;
$convertAmount: #2f3133;
$convertAmountText-light: #20201e;
$convertAmountText: #e4e6eb;
$convertLabel-light: #505050;
$convertLabel: #939393;
$convertText-light: #20201e;
$convertText: #e4e6eb;
$introduceBackground: #ddd;
$landingTableHeader: #9e9e9e;
$lightModeTableHover: #f3f3f3;
$positiveColor: #1ade7c;
$negetiveColor: #f84960;
$successBackgroundColor: #274737;
$errorBackgroundColor: #3f2629;
$dashboardButton: #d4d4d4;
$bankCardBorder: #bbb;
$bankCardBorderHover: #444;
$bankCardHover: #121519;
$notAcceptedBankCard: #939393;
$bankCardBorder: #939393;
$whiteText: #e5e5e5;
$hintText: #cacaca;
$hintTitleBackground: #4d4d4d;
$selectBorder: #939393;
$iconInPrimaryBackground: #121519;
$primaryHover: #f3dcdc;
$tooltip: #18191a;
$tooltipBackground: #e4e6eb;
$tableButtonHover: #3c3f42;
$linearProgressDefault: #494a4b;
$darkText: #18191a;
$defaultReferralBackground: #18191a;
$tabInactive: #d4d4d4;
$tabBorder: #494a4b;
$marketType: #9e9e9e;
$favoriteStar: #808080;
$notification: #e4e6eb;
$notificationDescription: #939393;
$menuLanding: #e4e6eb;
$deactiveTabText-light: #939393;
$activeTabText-dark: #d4d4d4;
$deactiveTabBorder-light: #ddd;
$deactiveTabBorder-dark: #2f3133;
$sideMenuHover: #2f3133;
$menuDivider: #9a9a9a;
$pending: #f08e0b;
$hintIcon: #f1972f;
$deactiveButton: #dee0e4;
$deactiveButtonBackground: #282b31;
$formBackground: #282b31;
$hintBackground: #433425;
$announceBackground: #402408;
$scrollbarTrack: #404040;
$scrollbarThumb: #808080;
$materialTableHeader: #888;
$materialTableBorder: #444;
$materialTableBodyBorder: #333;
