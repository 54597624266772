@import "../../../styles/Colors.scss";

.main {
    display: flex;
}

.buttonsDiv {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.loginButton {
    cursor: pointer;
    padding: 0.4em 1.5em;
    margin: 0 1em;
}

.loginButton:hover {
    color: $primary;
}

.registerButton {
    width: 9em;
}

.loggedInUserDiv {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.referralIcon {
    cursor: pointer;
    width: 2em;
    height: 2em;
    margin-left: 1em;
}

.notificationIcon {
    cursor: pointer;
    width: 1.8em;
    height: 1.8em;
}

.levelupButton {
    padding: 0.5em 1em;
}

#levelIcon {
    width: 1em;
    height: auto;
    margin-right: 0.4em;
}

.profileDiv {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
}

.userAvatar {
    cursor: pointer;
    border: 2px solid $primary;
}

.userIcon {
    width: 2.4em;
    height: 2.4em;
}

.menu {
    min-width: 13rem !important;
    background-color: $dropDownMenuBackground !important;
    color: $dropDownMenu !important;
    margin-top: 0.6em !important;
}

.menuItem {
    padding: 0.5em 0.8em !important;
    fill: $dropDownMenu;
}

.menuItem:hover {
    background-color: $menuHoverBackground;
    color: $primary;
    fill: $primary;
}

.menuDivider {
    background-color: $menuDivider !important;
    margin: 0.5em 1em !important;
}

.levelMenuItem {
    color: $primary;
}

.userLevelDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0.2em;
}

.separatorMenuItem {
    padding: 0 !important;
}

.separator {
    width: 100%;
    height: 0.5px;
    background-color: $borderseprator;
    margin: 0.2em 0;
}

#userMenuItemIcon {
    width: 1em;
    height: auto;
    margin-left: 0.25em;
}

#menuItemIcon {
    width: 0.9em;
    height: auto;
    margin-left: 0.4em;
}

.securityMenuItemIcon {
    width: 1.2em;
    margin-left: 0.8em;
    margin-right: 0.15em;
}

.badgeRoot {
    background-color: #f84960;
}

.notificationDiv {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1em;
}

.notifTitleDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $darkModeText;
}

.allNotifButton {
    cursor: pointer;
    color: $primary;
}

.emptyMessageText {
    width: 100%;
    text-align: center;
    margin: 1em 0;
}

.menuNotif {
    width: 22em !important;
    background-color: $dropDownMenuBackground !important;
    color: $dropDownMenu !important;
    margin-top: 0.6em !important;
}

.menuItemNotif {
    padding: 0.5em 1em !important;
    margin: 0.5em 0 !important;
    fill: $dropDownMenu;
}

.menuItemNotif:hover {
    background-color: $menuHoverBackground;
    color: $primary;
    fill: $primary;
}

.disabledMenuItem {
    padding: 0.5em 1em !important;
    fill: $dropDownMenu;
}

.notifMessageDiv {
    display: flex;
    justify-content: space-between;
}

.notifTitle {
    flex: 1;
    display: flex;
    align-items: center;
    color: $notification;
}

.unreadCircle {
    width: 0.8em;
    height: 0.8em;
    border-radius: 0.4em;
    background-color: $primary;
    margin-left: 0.6em;
}

.readCircle {
    background-color: $itemBackground;
}

.dateText {
    display: flex;
    color: $secondaryTextColor;
    margin-right: 1em;
}

.messageMainDiv {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.messageTimeText {
    white-space: pre;
    color: $notificationDescription;
    margin: 0.5em 0 1.5em;
}

.messageDescription {
    color: $notificationDescription;
    margin-bottom: 1em;
}

@media screen and (min-width: 600px) and (max-width: 960px) {
    .registerButton {
        width: 7em;
    }
}

@media screen and (max-width: 600px) {
    .notificationIcon {
        width: 1.5em;
        height: 1.5em;
    }

    .registerButton {
        width: 6em;
    }

    .levelupButton {
        padding: 0.4em 0.6em !important;
        border-radius: 0.3em !important;
    }

    .userIcon {
        width: 1.8em;
        height: 1.8em;
    }

    .notificationDiv {
        margin-left: 0.8em;
    }

    .profileDiv {
        margin-right: 0.8em;
    }
}
