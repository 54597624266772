@import "../../styles/Colors.scss";

.main {
    display: flex;
    flex-direction: column;
    background-color: $menuBackground;
    padding: 0.5em 1em 0;
}

.titleSpan {
    color: $menuTitle;
    margin-bottom: 0.5em;
}

@media screen and (min-width: 600px) {
    .dataDiv {
        padding: 0.5em 1.5em 0;
    }
}
