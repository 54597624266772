@import "../../styles/Colors.scss";

.dialog {
    background-color: transparent !important;
}

.mainDiv {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1.5em;
    border-radius: 10px;
}

.titleDiv {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
}

.iconDiv {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $itemBackground;
}

#cancelIcon {
    font-size: 1.2em;
    padding: 0.3em;
}

.itemsSpan {
    text-align: justify;
    margin: 0.5em 0;
}

.closeButton {
    margin-top: 1.5em;
}
