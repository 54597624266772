@import "../../styles/Colors.scss";

.main {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7em 1em;
    border-radius: 5px;
    border: none;
    color: $enabledButton;
    background-color: $enabledButtonBackground;
    outline: none;
}

.main:disabled {
    cursor: not-allowed;
    color: $disabledButton;
    background-color: $disabledButtonBackground;
}

.mainIconButton {
    cursor: pointer;
    color: $primary;
    background-color: transparent;
    border: none;
    outline: none;
}

.loaderSpan {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.1em 0.4em 0 -0.8em;
}

@media screen and (max-width:600px) {
    .main {
        padding: 0.6em 0.4em;
    }
}
