@import "../../styles/Colors.scss";

.dialog {
    background-color: transparent !important;
    box-shadow: none !important;
}

.mainDiv {
    width: 16rem;
    height: 14rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    // background-color: $loadingBackground;
}

.textSpan {
    color: $primary;
    margin: 2em 0 0;
}
