@import url(./app/styles/Font.css);

html {
  scroll-behavior: smooth;
}

body {
  font-family: IRANSansX;
  font-weight: 300;
  direction: rtl;
  margin: 0;
  line-height: 1.9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  all: unset;
}

h1,
h2,
h3,
h4,
h5,
h6,
input,
select,
textarea,
button {
  font-family: IRANSansX;
}

h1 {
  font-size: 1.6em !important;
  font-weight: bold;
}

h2 {
  font-size: 1.4em !important;
  font-weight: bold;
}

h3 {
  font-size: 1.2em !important;
  font-weight: bold;
}

blockquote {
  font-weight: 500;
  padding: 10px;
  border: 1px dashed #666666;
}

input {
  filter: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  caret-color: #e4e6eb;
  -webkit-text-fill-color: #e4e6eb;
  -webkit-box-shadow: 0 0 0 1000px #2f3133 inset;
}

.persian {
  font-family: IRANSansX;
}

.number {
  font-family: Bahnschrift;
}

.text-xsmall {
  font-size: 0.4em;
}

.text-small {
  font-size: 0.6em;
}

.text-medium {
  font-size: 0.8em;
}

.text-large {
  font-size: 1em;
}

.text-xlarge {
  font-size: 1.2em;
}

.text-2xlarge {
  font-size: 1.4em;
}

.text-ultralight {
  font-weight: 200;
}

.text-light {
  font-weight: 300;
}

.text-bold {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 1.4em;
    font-weight: bold;
  }

  h2 {
    font-size: 1em;
    font-weight: bold;
  }

  .text-xsmall {
    font-size: 0.3em;
  }

  .text-small {
    font-size: 0.5em;
  }

  .text-medium {
    font-size: 0.7em;
  }

  .text-large {
    font-size: 0.9em;
  }

  .text-xlarge {
    font-size: 1.1em;
  }

  .text-2xlarge {
    font-size: 1.3em;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  h1 {
    font-size: 1.6em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.2em;
    font-weight: bold;
  }

  .text-xsmall {
    font-size: 0.4em;
  }

  .text-small {
    font-size: 0.6em;
  }

  .text-medium {
    font-size: 0.8em;
  }

  .text-large {
    font-size: 1em;
  }

  .text-xlarge {
    font-size: 1.2em;
  }

  .text-2xlarge {
    font-size: 1.4em;
  }
}
