@import "../../styles/Colors.scss";

.main {
    cursor: pointer;
    margin: 0 0 0 1.5em;
}

.activeTab {
    color: $primary;
    border-bottom: 1px solid $primary;
    padding: 0 0 0.5em;
}
