@import "../../styles/Colors.scss";

.main {
    display: flex;
    flex-direction: column;
    // height: 100vh;
    width: 100%;
}

.backgroundDiv {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.homeTheme {
    background-color: $homeBackground;
}

a {
    color: $darkModeText;
}
