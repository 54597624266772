@import "../../styles/Colors.scss";

.bottomMenu {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 64px;
    display: flex;
    justify-content: space-between;
    background-color: $menuBackground;
    box-shadow: 0 0 13px 0 rgba(4, 3, 3, 0.8);
    z-index: 10;
}

.rightMenu {
    position: fixed;
    top: 64px;
    bottom: 0;
    width: 72px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $menuBackground;
    box-shadow: 0 0 13px 0 rgba(4, 3, 3, 0.8);
}

.icon {
    width: 1.4rem;
    height: 1.4rem;
}
