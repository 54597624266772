@import "../../../styles/Colors.scss";

.main {
    height: 72px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.main:hover {
    background-color: $sideMenuHover;
}

.title {
    margin: 1px 0;
}

.activeTitle {
    color: $primary;
}
